import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

const components = {}

const MdxPage = ({
  data: {
    mdx: {
      frontmatter: { embeddedImagesLocal },
      body,
    },
  },
}: {
  data: { mdx: { frontmatter: { embeddedImagesLocal: string[] }; body: string } }
}): JSX.Element => {
  return (
    <>
      <MDXProvider components={components}>
        <MDXRenderer localImages={embeddedImagesLocal}>{body}</MDXRenderer>
      </MDXProvider>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        embeddedImagesRemote {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`

export default MdxPage
